import { makeAutoObservable, action, toJS } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import creatorV2Services, { ICheckCreatorBaseInfoParams } from '@/services/creatorV2Services';
import userV2Services from '@/services/userV2Services';
import {
  CreatorType,
  OperateCreatorType,
  OperatePlatformType,
  BusinessInfoType,
  // CreatorDetailType,
  AccountPassportChangeType,
  CategoriesType,
  AddCreatorType,
  DeleteOperatePlatformParams,
  AddAccountParamsType,
  AccountCreatorType,
  AccountPlatformIsStoppedParamsType,
  UserInfoType,
  UserParamsType,
  CreatorListType,
  AccountForeignPlatforms,
  AddCreatorAccountParams,
} from './types';

export default class AccountV2Store {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'AccountV2Store',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  creatorTabKey: string = '';

  setCreatorTabKey(val: string) {
    this.creatorTabKey = val;
  }

  creatorList: CreatorType[] = [];
  async getCreatorList(params?: UserParamsType) {
    const res = await creatorV2Services.creatorList(params);

    // res?.data?.list?.sort(
    //   (a: CreatorListType, b: CreatorListType) => (a?.user?.adoba_id || 0) - (b?.user?.adoba_id || 0)
    // );
    let userList: CreatorType[] = [];
    res?.list.forEach((item) => {
      this.creatorList.push(item.user);
      // console.log(userList);
    });
    // this.creatorList = userList;

    // this.creatorList = res.data.list.map((item) => {
    //   const fans = (item.platform_data || []).reduce((previous, current) => {
    //     return previous + current.new_fans;
    //   }, 0);
    //   const views = (item.platform_data || []).reduce((previous, current) => {
    //     return previous + current.new_views;
    //   }, 0);
    //   return {
    //     ...item,
    //     fans,
    //     views,
    //   };
    // });
    // return this.dealCreatorListData();
    return Promise.resolve(res);
  }

  // favoriteList: number[] = [];
  // async getFavoriteList() {
  //   const res = await userV2Services.getFavoriteList();
  //   this.favoriteList = res.data || [];
  //   return this.dealCreatorListData();
  // }

  // manageList: number[] = [];
  // async getManageList() {
  //   const res = await userV2Services.getManageCreatorList();
  //   this.manageList = res.data || [];
  //   return this.dealCreatorListData();
  // }

  // 处理list data 加入是否收藏字段
  // dealCreatorListData() {
  //   if (this.creatorList.length > 0) {
  //     const array = this.creatorList.slice().map((creator) => {
  //       // const isFav = this.favoriteList.includes(creator.id);
  //       // const isManage = this.manageList.includes(creator.id);
  //       const newCreator = {
  //         ...creator,
  //         // is_fav: isFav ? 1 : 0,
  //         // is_manage: isManage ? 1 : 0,
  //       };
  //       if (newCreator.uid === this.creator.uid) {
  //         this.creator = Object.assign({}, newCreator);
  //       }
  //       return newCreator;
  //     });
  //     this.creatorList = array;
  //   }
  //   if (this.searchList.length > 0) {
  //     const array = this.searchList.slice().map((creator) => {
  //       const isFav = this.favoriteList.includes(creator.uid);
  //       const isManage = this.manageList.includes(creator.uid);
  //       const newCreator = {
  //         ...creator,
  //         is_fav: isFav ? 1 : 0,
  //         is_manage: isManage ? 1 : 0,
  //       };
  //       if (newCreator.id === this.creator.id) {
  //         this.creator = Object.assign({}, newCreator);
  //       }
  //       return newCreator;
  //     });
  //     this.searchList = array;
  //   }
  //   return Promise.resolve([]);
  // }

  async addFavorite(id: number, option: number) {
    const params = {
      option,
      creator_id: id,
    };
    const res = await userV2Services.addFavorite(params);
    return Promise.resolve(res.data);
  }

  // searchList: CreatorType[] = [];
  // async searchCreators(name: string) {
  //   const res = await creatorV2Services.searchCreator(name);
  //   if (res.status === 0) {
  //     this.searchList = res.data || [];
  //     return this.dealCreatorListData();
  //   }
  //   return Promise.resolve([]);
  // }

  // clearSearch() {
  //   this.searchList = [];
  // }

  // 手动排序
  // sortCreatorList(key: string, sort: number, isSearch = false) {
  //   const array = isSearch ? toJS(this.searchList).slice() : toJS(this.creatorList).slice();
  //   array.sort((a, b) => {
  //     if (key === 'no') {
  //       return sort === 1
  //         ? (a.adoba_id || 0) - (b.adoba_id || 0)
  //         : (b.adoba_id || 0) - (a.adoba_id || 0);
  //     } else if (key === 'fans') {
  //       return sort === 1 ? (a.fans || 0) - (b.fans || 0) : (b.fans || 0) - (a.fans || 0);
  //     } else if (key === 'views') {
  //       return sort === 1 ? (a.views || 0) - (b.views || 0) : (b.views || 0) - (a.views || 0);
  //     } else {
  //       return sort === 1
  //         ? (a.adoba_id || 0) - (b.adoba_id || 0)
  //         : (b.adoba_id || 0) - (a.adoba_id || 0);
  //     }
  //   });
  //   return array;
  // }

  creator: CreatorType = {
    // adoba_id: 0,
    // category: '',
    categoryId: 0,
    countryCode: '',
    gender: 0,
    gid: '',
    password: '',
    role: 'NORMAL',
    avatar: '',
    // create_time: '',
    email: '',
    uid: 0,
    // id: 0,
    intro: '',
    // is_fav: 0,
    // is_manage: 0,
    nickname: '',
    phone: '',
    level: '',
    // user_id: 0,
  };
  async setCreator(creator: CreatorType) {
    this.creator = creator;
  }

  //是否展示运营日志
  showLog: boolean = false;
  setShowLog(showLog: boolean) {
    this.showLog = showLog;
  }

  /* creatorDetail: CreatorDetailType = {};
  async getCreatorDetail(id: number) {
    const res = await creatorV2Services.creatorDetail(id);
    if (res.status === 0) {
      this.creatorDetail = res.data;
    }
    return Promise.resolve(res.data);
  } */

  // 查询运营平台
  async getOperatePlatformInfo(uid?: number, platform_id = 0) {
    const params = {
      uid,
      platform_id, // 不传或传0的时返回所有平台
    };
    const res = await userV2Services.getOperatePlatformInfo(params);
    return Promise.resolve(res.data);
  }

  // 查询运营信息
  async getOperateCreatorInfo(uid?: number) {
    const params = {
      uid,
    };
    const res = await userV2Services.getOperateCreatorInfo(params);
    return Promise.resolve(res.data);
  }

  // 新增和修改运营信息
  async operateCreator(params: OperateCreatorType) {
    const res = await userV2Services.operateCreator(params);
    return Promise.resolve(res.data);
  }

  // 新增和修改运营平台
  async operatePlatform(params: OperatePlatformType) {
    const res = await userV2Services.operatePlatform(params);
    return Promise.resolve(res);
  }

  businessInfo: BusinessInfoType[] = [];
  // 获取商务信息
  async getBusinessInfo(id: number) {
    const res = await creatorV2Services.getBusinessInfo(id);
    const data = res.list || [];
    return Promise.resolve(data);
  }

  async addBusinessInfo(params: BusinessInfoType) {
    const res = await creatorV2Services.addBusinessInfo(params);
    return Promise.resolve(res.data);
  }

  async updateBusinessInfo(params: BusinessInfoType) {
    const res = await creatorV2Services.updateBusinessInfo(params);
    return Promise.resolve(res.data);
  }

  async deleteBusinessInfo(id: number) {
    const res = await creatorV2Services.deleteBusinessInfo(id);
    return Promise.resolve(res.data);
  }

  // 获取creator的所有账户分类
  categoriesList: CategoriesType[] = [];
  async getCategories(page = 1, size = 999) {
    if (this.categoriesList?.length > 0) {
      return Promise.resolve(this.categoriesList);
    }
    const params = {
      size,
      page,
    };
    const res = await creatorV2Services.getCategories(params);
    this.categoriesList = res.data;
    // this.getDisplayCateList(res.data);
    return Promise.resolve(res.data);
  }

  // displayCateList: CategoriesType[] = [];
  getDisplayCateList(language: 'cn' | 'en' | 'kr' = 'cn') {
    const array = this.categoriesList.reduce((arr: CategoriesType[], item) => {
      if (item.parent_id === 0) {
        arr.push({
          ...item,
          label: item[`${language}_name`],
          value: item.category_id,
        });
      } else if (item.category_id > 99999 && item.category_id < 999999) {
        arr.forEach((a) => {
          if (item.parent_id === a?.category_id) {
            let newItem = {
              ...item,
              label: item[`${language}_name`],
              value: item.category_id,
            };
            if (a?.children) {
              a.children.push(newItem);
            } else {
              a.children = [newItem];
            }
          }
        });
      } else {
        arr.forEach((a) => {
          a.children?.forEach((b) => {
            if (item.parent_id === b?.category_id) {
              let newItem = {
                ...item,
                label: item[`${language}_name`],
                value: item.category_id,
              };
              if (b?.children) {
                b.children.push(newItem);
              } else {
                b.children = [newItem];
              }
            }
          });
        });
      }
      return arr;
    }, []);
    return array;
  }

  displayCateName(id?: number, language: 'cn' | 'en' | 'kr' = 'cn') {
    if (!id) {
      return '-';
    }
    let cate = '';
    let cateParentId = id;
    do {
      // eslint-disable-next-line no-loop-func
      const cateItem = this.categoriesList?.find((item) => item.category_id === cateParentId);
      if (cateItem) {
        cate = cateItem[`${language}_name`] + `${cate ? `/${cate}` : cate}`;
        cateParentId = cateItem.parent_id;
      } else {
        cateParentId = 0;
      }
    } while (cateParentId !== 0);
    return cate || '-';
  }

  // 获取运营日志
  async getUserOperateLogs(size = 10, page = 1) {
    const params = {
      size,
      page,
      uid: this.creator.uid,
    };
    const res = await userV2Services.getUserOperateLogs(params);
    return Promise.resolve(res.data);
  }

  // // 获取达人平台最新数据 废弃
  // async getCreatorPlatformData(id: number) {
  //   const res = await creatorV2Services?.getCreatorPlatformData(id);
  //   return Promise.resolve(res.data);
  // }

  // 获取达人各平台的数据
  async getCreatorPlatformsData(uid: number) {
    const res = await creatorV2Services?.getCreatorPlatformsData(uid);
    return Promise.resolve(res);
  }

  // 用户信息
  userInfoData: UserInfoType[] = [];
  // 获取用户信息
  async getUserInfo(name: string = '') {
    const params = {
      content: name,
    };
    const res = await userV2Services?.getUserInfo(params);
    this.userInfoData = res.data;
    return Promise.resolve(res.data);
  }

  //修改达人基本信息
  async changeCreatorBaseInfoData(params?: AccountCreatorType) {
    const res = await creatorV2Services?.changeCreatorBaseInfoData(params);
    return Promise.resolve(res);
  }

  //获取达人护照信息
  async getCreatorPassInfo(uid?: number) {
    const res = await creatorV2Services?.getCreatorPassInfo(uid);
    return Promise.resolve(res);
  }

  //获取达人海外平台信息
  async getForeignPlatform(uid?: number) {
    const res = await creatorV2Services?.getForeignPlatform(uid);
    return Promise.resolve(res.foreignPlatform);
  }

  //获取达人海外平台信息
  async changeForeignPlatform(params?: AccountForeignPlatforms) {
    const res = await creatorV2Services?.changeForeignPlatform(params);
    return Promise.resolve(res);
  }

  //获取达人运营平台列表
  async getOperationPlatformList(uid?: number) {
    const res = await creatorV2Services?.getOperationPlatformList(uid);
    return Promise.resolve(res.list);
  }

  //获取达人所属MCN信息
  async getMCNInfo(uid?: number) {
    const res = await creatorV2Services?.getMCNInfo(uid);
    return Promise.resolve(res.invitation);
  }
  // 添加达人
  async addCreator(params: AddCreatorType) {
    const res = await creatorV2Services?.addCreator(params);
    return Promise.resolve(res.data);
  }

  // 删除平台
  async deleteOperatePlatform(params: DeleteOperatePlatformParams) {
    const res = await userV2Services.deleteOperatePlatform(params);
    return Promise.resolve(res.data);
  }

  async checkCreatorBaseInfo(params: ICheckCreatorBaseInfoParams) {
    const res = await creatorV2Services?.checkCreatorBaseInfo(params);
    return Promise.resolve(res.data);
  }

  // 添加新达人的基本信息
  async addCreatorBaseInfo(params: AccountCreatorType) {
    const res = await creatorV2Services?.addCreatorBaseInfo(params);
    return Promise.resolve(res.data);
  }

  // 添加新达人的隐私信息
  async addCreatorPrivacyInfo(params: AddAccountParamsType) {
    const res = await creatorV2Services?.addCreatorPrivacyInfo(params);
    return Promise.resolve(res.data);
  }

  async changePassportInfo(params: AccountPassportChangeType) {
    const res = await creatorV2Services?.changePassportInfo(params);
    return Promise.resolve(res);
  }

  // 查询达人的隐私信息
  async getCreatorPrivacyData(id?: number) {
    const res = await creatorV2Services?.getCreatorPrivacyData(id);
    return Promise.resolve(res.data);
  }

  creatorInfo: CreatorType = {
    // adoba_id: 0,
    // category: '',
    categoryId: 0,
    countryCode: '',
    gender: 0,
    gid: '',
    password: '',
    role: 'NORMAL',
    avatar: '',
    // create_time: '',
    email: '',
    uid: 0,
    // id: 0,
    intro: '',
    // is_fav: 0,
    // is_manage: 0,
    nickname: '',
    phone: '',
    level: '',
    // user_id: 0,
  };
  async setCreatorInfo(creator: CreatorType) {
    this.creatorInfo = creator;
  }

  // 根据ID查询达人的基本信息
  async getCreatorBaseInfoData(uid?: number, toUpdate: boolean = false) {
    const res = await creatorV2Services?.getCreatorBaseInfoData(uid);
    if (toUpdate) {
      this.handleCreatorList(res);
    }

    this.setCreatorInfo(res);
    return Promise.resolve(res);
  }

  // 在CreatorList中更新单个达人信息
  handleCreatorList(data: CreatorType) {
    const array = this.creatorList?.slice().map((item) => {
      if (item?.uid === data?.uid) {
        const newCreator = {
          ...data,
          // is_fav: item?.is_fav,
          // is_manage: item?.is_manage,
        };
        this.creatorInfo = newCreator;
        this.creator = newCreator;
        return newCreator;
      } else {
        return item;
      }
    });
    this.creatorList = array;

    // if (this.searchList.length > 0) {
    //   const array = this.searchList?.slice().map((item) => {
    //     if (item?.id === data?.id) {
    //       const newCreator = {
    //         ...data,
    //         is_fav: item?.is_fav,
    //         is_manage: item?.is_manage,
    //       };
    //       return newCreator;
    //     } else {
    //       return item;
    //     }
    //   });
    //   this.searchList = array;
    // }
  }

  // 下载运营信息
  async downloadsOperation() {
    const res = await userV2Services?.downloadsOperation();
    return Promise.resolve(res);
  }

  // 更新isStopped爬虫状态
  async updateIsStopped(params: AccountPlatformIsStoppedParamsType) {
    const res = await userV2Services?.updateIsStopped(params);
    return Promise.resolve(res);
  }

  // 添加新的会员账号
  async addCreatorAccount(params: AddCreatorAccountParams) {
    const res = await creatorV2Services?.addCreatorAccount(params);
    return Promise.resolve(res);
  }
}
