import axios from 'axios';
import { message } from 'antd';
import Cookie from 'js-cookie';
import CONFIG from '../config';
import { tf } from '@/i18n/config';

const authInfo = {
  onLogout: () => undefined,
  onLogin: () => undefined,
};

function setAuthInfo(onLogout: () => any, onLogin: () => any) {
  authInfo.onLogout = onLogout;
  authInfo.onLogin = onLogin;
}

const a = axios.create({
  baseURL: CONFIG.BASEURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 30000,
});

a.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('studio_token');
    config.headers = Object.assign({}, config.headers, {
      token,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.status === 200) {
      authInfo.onLogin();
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);

    const timer = setTimeout(() => {
      timer && clearTimeout(timer);
      reactOnStatusCode(errorObj);
    }, 1000);

    return Promise.reject(errorObj.data);
  }
);

/* 下载文件流 BASEURL导出文件 */
const a2 = axios.create({
  baseURL: CONFIG.BASEURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  responseType: 'blob',
  timeout: 30000,
});

a2.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('studio_token');
    config.headers = Object.assign({}, config.headers, {
      token,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a2.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.data) {
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);
    setTimeout(() => {
      reactOnStatusCode(errorObj);
    }, 5000);
    return Promise.reject(errorObj.data);
  }
);

/* data */
const a3 = axios.create({
  baseURL: CONFIG.DATAURL,
  headers: {
    'Content-type': 'application/json',
    'A-Branch': 'feat/income-tax', // Ion分支，部署生产时可去掉
    Accept: 'application/json',
  },
  timeout: 30000,
});

a3.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('studio_token');
    config.headers = Object.assign({}, config.headers, {
      token,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a3.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.status === 200) {
      authInfo.onLogin();
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);

    const timer = setTimeout(() => {
      timer && clearTimeout(timer);
      reactOnStatusCode(errorObj);
    }, 1000);

    return Promise.reject(errorObj.data);
  }
);

/* 下载文件流 DATAURL导出文件 */
const a4 = axios.create({
  baseURL: CONFIG.DATAURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  responseType: 'blob',
  timeout: 30000,
});

a4.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('studio_token');
    config.headers = Object.assign({}, config.headers, {
      token,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a4.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.data) {
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);
    setTimeout(() => {
      reactOnStatusCode(errorObj);
    }, 5000);
    return Promise.reject(errorObj.data);
  }
);

const dataURL =
  window.location.host === 'v2.adoba.studio'
    ? 'https://api-ai.adoba.studio'
    : 'https://dev.api-ai.adoba.studio';
const a5 = axios.create({
  baseURL: dataURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    secret: 'hi!oro#e237ge7@',
  },
  timeout: 28800000,
});
a5.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('studio_token');
    config.headers = Object.assign({}, config.headers, {
      token,
    });
    return config;
  },
  (error) => Promise.reject(error)
);
a5.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.status === 200) {
      authInfo.onLogin();
      // console.log(response.headers['Content-Disposition']);
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);

    const timer = setTimeout(() => {
      timer && clearTimeout(timer);
      reactOnStatusCode(errorObj);
    }, 5000);

    return Promise.reject(errorObj.data);
  }
);

function getErrorObject(response: any) {
  // No response data, probably no network or 500
  console.log('error ', response);
  let errorCode = -1;
  let errorMessage = 'Server Error,  Can not Access To The Server';
  if (response && response.status) {
    errorCode = response.status;
    errorMessage = response.data.msg || 'Server Error,  Can not Access To The Server';
  }

  return {
    ...response,
    code: errorCode,
    message: errorMessage,
    status: response ? response?.status : -1,
  };
}

function reactOnStatusCode(error: any) {
  if (error) {
    switch (error.status) {
      case 401:
        // 登录状态失效
        authInfo.onLogout();
        break;
      // case 403:
      // 无权限
      // authInfo.onLogout();
      // break;
      default:
        break;
    }
  }
}

function showErrorMessage(error: any) {
  if (error) {
    switch (error.status) {
      case 401:
        message.warning(tf('global.message_no_token'));
        break;
      case 403:
        message.warning(tf('global.message_no_permission'));
        break;
      default:
        console.log(error.message);
        break;
    }
  }
}

function getResult(response: any) {
  const errorMessage = { message: 'Server Error,  Can not Access To The Server' };

  if (response && response.data) {
    return response.data;
  }

  throw errorMessage;
}

export { a as axios, a2 as axios2, a3 as axios3, a4 as axios4, a5 as axios5, setAuthInfo };
