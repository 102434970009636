import { axios, axios2, axios3 } from '../utils/axios';
import { LoginType } from '@/pages/Login/types';
import { RolesType, UserType } from '@/stores/UserStore/types';
import {
  OperatePlatformType,
  OperateCreatorType,
  CreatorOperatePlatformsType,
  OperateLogsType,
  PlatformInfoType,
  UserInfoType,
  CreatorOperateInfoType,
  AccountPlatformIsStoppedParamsType,
} from '@/stores/AccountV2Store/types';

export interface IGetUserResponse {
  data: {
    user: UserType;
    roles: RolesType[];
  };
  msg?: string;
  status?: number;
}

export interface IGetFavoriteResponse {
  data: number[];
  msg?: string;
  status?: number;
}

export interface IGetManageListResponse {
  status: number;
  msg: string;
  data: number[];
}

export interface IAddFavoriteParams {
  creator_id: number;
  option: number; // 0: add, 1: cancel
}

export interface IGetOperateInfoParams {
  uid?: number;
  // adoba_id: number;
}

export interface IGetOperatePlatformInfoParams {
  uid?: number;
  platform_id?: number;
}

export interface IGetOperatePlatformInfoResponse {
  data: PlatformInfoType[];
  msg?: string;
  status?: number;
}

export interface IGetCreatorOperateInfoResponse {
  data: CreatorOperateInfoType;
  msg?: string;
  status?: number;
}

export interface IGetUserOperateLogsParams {
  page: number;
  size: number;
  uid?: number;
}

export interface IGetOperateLogsResponse {
  status: number;
  msg: string;
  data: OperateLogsType[];
}

export interface IGetUserInfoResponse {
  status: number;
  msg: string;
  data: UserInfoType[];
}

export interface IDeleteOperatePlatform {
  creator_id: number;
  platform_id: number;
}

const userV2Services = {
  getFavoriteList: () =>
    axios.get<IGetFavoriteResponse, IGetFavoriteResponse>('/api/user/favorites'),

  getManageCreatorList: () =>
    axios.get<IGetManageListResponse, IGetManageListResponse>('/api/user/operates/creator_ids'),

  addFavorite: (params: IAddFavoriteParams) => axios.put('/api/user/favorites', params),

  // 获取运营平台
  getOperatePlatformInfo: (params: IGetOperatePlatformInfoParams) =>
    axios.get<IGetOperatePlatformInfoResponse, IGetOperatePlatformInfoResponse>(
      '/studio/v2/user/operation_platform',
      { params }
    ),

  // 获取运营信息
  getOperateCreatorInfo: (params: IGetOperateInfoParams) =>
    axios.get<IGetCreatorOperateInfoResponse, IGetCreatorOperateInfoResponse>(
      '/api/user/operates/creator',
      { params }
    ),

  // 新增|修改运营信息
  operateCreator: (params: OperateCreatorType) => axios.post('/api/user/operates/creator', params),

  // 新增|修改运营平台
  operatePlatform: (params: OperatePlatformType) =>
    axios3.patch('/studio/v2/user/operating_platform', params),

  getUserOperateLogs: (params: IGetUserOperateLogsParams) =>
    axios.get<IGetOperateLogsResponse, IGetOperateLogsResponse>('/api/user/op_history', { params }),

  getUserInfo: (params: { content: string }) =>
    axios.get<IGetUserInfoResponse, IGetUserInfoResponse>('/api/user/search', { params }),

  deleteOperatePlatform: (params: IDeleteOperatePlatform) =>
    axios.delete('/api/user/operates/platform', { params }),

  downloadsOperation: () => axios2.post('/api/user/operates/download'),

  // isStopped
  updateIsStopped: (params: AccountPlatformIsStoppedParamsType) =>
    axios.patch('/api/user/operates/platform_stopping', params),
};

export default userV2Services;
