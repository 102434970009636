import { axios, axios3 } from '../utils/axios';
import {
  CreatorType,
  /* CreatorDetailType, */
  BusinessInfoType,
  CategoriesType,
  CreatorPlatformDataType,
  AddAccountParamsType,
  AccountCreatorType,
  CreatorPrivacyDataType,
  SeriesFansListItemType,
  CreatorListType,
  AccountForeignPlatforms,
  PlatformInfoType,
  UserParamsType,
  IOperationPlatformResponse,
  AccountPassportChangeType,
  accountMCNInfoType,
  AddCreatorAccountParams,
} from '@/stores/AccountV2Store/types';
import { CreatorFeedbackType } from '@/stores/FeedbackStore/types';

export interface IAddCreatorResponse {
  data: {
    creator_id: number;
  };
  msg: string;
  status: number;
}

export interface ISearchCreatorResponse {
  data: CreatorType[];
  msg: string;
  status: number;
}

export interface IPlatformListResponse {
  list: PlatformInfoType[];
}

export interface ICreatorListResponse {
  count: number;
  list: CreatorListType[];
}

/* export interface ICreatorDetailResponse {
  data: CreatorDetailType;
  msg: string;
  status: number;
} */

export interface IGetBusinessInfoResponse {
  list?: BusinessInfoType[];
  // msg?: string;
  // status?: number;
}

export interface IAddBusinessInfoResponse {
  data: null;
  msg?: string;
  status?: number;
}

export interface IGetCategoriesResponse {
  data: CategoriesType[];
  msg?: string;
  status?: number;
}

export interface IGetCategoriesParams {
  page: number;
  size: number;
}

export interface IGetCreatorPlatformDataResponse {
  data: {
    platform_data?: CreatorPlatformDataType[];
  };
  msg?: string;
  status?: number;
}

// 获取达人各平台的数据
export interface IGetCreatorPlatformsDataResponse {
  times: string[];
  series: SeriesFansListItemType[];
}

export interface IGetCreatorPrivacyDataResponse {
  data: CreatorPrivacyDataType;
  msg?: string;
  status?: number;
}

export interface IGetCreatorBaseInfoResponse {
  data: CreatorType;
}

export interface ICheckCreatorBaseInfoParams {
  phone?: string;
  nickname?: string;
  adoba_id?: number;
}

export interface ICheckCreatorBaseInfoResponse {
  data: string;
  msg?: string;
  status: number;
}

export interface ICreatorFeedbackParams {
  type: number;
  page: number;
  size: number;
}

export interface ICreatorPassInfoResponse {
  authNum: string;
  name: string;
  copyUrl: string;
  copyMoreUrl: string;
  expiryDate: string;
  countryId: number;
}

export interface IForeignPlatformResponse {
  foreignPlatform: AccountForeignPlatforms;
}

export interface IMCNInfoResponse {
  invitation: accountMCNInfoType;
}

interface ICreatorFeedbackResponse {
  data: {
    list: CreatorFeedbackType[];
    total_num: number;
  };
  msg: string;
  status: number;
}

const creatorV2Services = {
  addCreator: (params: any) =>
    axios.post<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/add', params),

  searchCreator: (name: string) =>
    axios.get<ISearchCreatorResponse, ISearchCreatorResponse>(`/api/creator/search/${name}`),

  creatorList: (params?: UserParamsType) =>
    axios3.get<ICreatorListResponse, ICreatorListResponse>('/studio/v2/users', { params }),

  // 该接口已废弃，需要删除相关调用；达人相关详细信息，在后端添加字段后，从creatorList中获取
  // creatorDetail: (id: number) =>
  //   axios.get<ICreatorDetailResponse, ICreatorDetailResponse>(`/api/creator/v2/${id}`),

  getBusinessInfo: (id: number) =>
    axios3.get<IGetBusinessInfoResponse, IGetBusinessInfoResponse>(
      '/studio/v2/user/platform/ad_incomes',
      { params: { uid: id } }
    ),

  addBusinessInfo: (params: BusinessInfoType) =>
    axios.post<IAddBusinessInfoResponse, IAddBusinessInfoResponse>(
      '/api/creator/ad_income',
      params
    ),

  updateBusinessInfo: (params: BusinessInfoType) =>
    axios3.patch<IAddBusinessInfoResponse, IAddBusinessInfoResponse>(
      '/studio/v2/user/platform/ad_incomes',
      params
    ),

  deleteBusinessInfo: (id: number) =>
    axios.delete<IAddBusinessInfoResponse, IAddBusinessInfoResponse>(
      `/api/creator/ad_income/${id}`
    ),

  getCategories: (params: IGetCategoriesParams) =>
    axios.get<IGetCategoriesResponse, IGetCategoriesResponse>('/api/creator/categories', {
      params,
    }),

  // 该接口已废弃
  // getCreatorPlatformData: (id: number) =>
  //   axios.get<IGetCreatorPlatformDataResponse, IGetCreatorPlatformDataResponse>(
  //     `/api/creator/dashboard/detail/${id}`
  //   ),

  // 查询达人各平台的粉丝数据
  getCreatorPlatformsData: (uid: number) =>
    axios3.get<IGetCreatorPlatformsDataResponse, IGetCreatorPlatformsDataResponse>(
      `/studio/v1/spider/incr_metric/${uid}`
    ),

  // 检测达人的基本信息是否重复
  checkCreatorBaseInfo: (params: ICheckCreatorBaseInfoParams) =>
    axios.get<ICheckCreatorBaseInfoResponse, ICheckCreatorBaseInfoResponse>('/api/creator/exist', {
      params,
    }),

  // 创建达人的基本信息
  addCreatorBaseInfo: (params: AccountCreatorType) =>
    axios.put<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/v2', params),

  // 创建达人的隐私信息
  addCreatorPrivacyInfo: (params: AddAccountParamsType) =>
    axios.put<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/v2/privacy', params),

  // 修改达人的护照信息
  changePassportInfo: (params: AccountPassportChangeType) =>
    axios3.put('/studio/v2/user/passport_info', params),

  // 获取达人的隐私信息
  getCreatorPrivacyData: (id?: number) =>
    axios.get<IGetCreatorPrivacyDataResponse, IGetCreatorPrivacyDataResponse>(
      `/api/creator/v2/privacy/${id}`
    ),

  // 获取达人的基本信息
  getCreatorBaseInfoData: (uid?: number) =>
    axios3.get<CreatorType, CreatorType>(`/studio/v2/user`, { params: { uid: uid } }),

  // 修改达人的基本信息
  changeCreatorBaseInfoData: (params?: AccountCreatorType) =>
    axios3.patch(`/studio/v2/user`, params),

  //获取达人的护照信息
  getCreatorPassInfo: (uid?: number) =>
    axios3.get<ICreatorPassInfoResponse, ICreatorPassInfoResponse>('/studio/v2/user/passport', {
      params: { uid: uid },
    }),

  //获取达人的海外平台信息
  getForeignPlatform: (uid?: number) =>
    axios3.get<IForeignPlatformResponse, IForeignPlatformResponse>(
      'studio/v2/user/foreign_platform_ai',
      { params: { uid: uid } }
    ),

  //修改达人的海外平台信息
  changeForeignPlatform: (params?: AccountForeignPlatforms) =>
    axios3.patch('studio/v2/user/foreign_platform_ai', { params }),

  //获取达人运营平台列表
  getOperationPlatformList: (uid?: number) =>
    axios3.get<IPlatformListResponse, IPlatformListResponse>('studio/v2/user/operating_platforms', {
      params: { uid: uid },
    }),

  // 获取达人所属MCN信息
  getMCNInfo: (uid?: number) =>
    axios3.get<IMCNInfoResponse, IMCNInfoResponse>('studio/v2/user/invitation', {
      params: { uid: uid },
    }),

  // 获取达人建议(from adoba Road)
  getCreatorFeedback: (params: ICreatorFeedbackParams) =>
    axios.get<ICreatorFeedbackResponse, ICreatorFeedbackResponse>('/api/creator/suggestions', {
      params,
    }),

  // 添加新达人账号
  addCreatorAccount: (params: AddCreatorAccountParams) => axios3.post('/studio/v2/user', params),
};
export default creatorV2Services;
